/* ------------------------------------ *\
		tools.action
\* ------------------------------------ */

@import "ui/styles/2-tools/tools.behavior.scss";

// Action
@mixin action {
	padding: 0;
	background: transparent;
	border-color: transparent;
	font-weight: var(--font-weight--semibold);
	cursor: pointer;
}

// Button
@mixin button {
	@include action;

	display: inline-block;
	padding: var(--spacing--sm) var(--spacing--md);
	text-decoration: none;
	line-height: var(--line-height--lg);
	border-radius: var(--corner-size--base);
	border: var(--border--md) solid transparent;
	text-align: center;
	min-width: 10em;
	font-size: var(--font-size--sm);

	@include transition {
		transition: all var(--trans-time--standard);
	}

	&___primary {
		color: var(--button-text-primary);
		background: var(--button-bg-primary);
		border: none;

		&:hover,
		&:focus {
			box-shadow: inset 0 -4.5em 0 0 var(--button-bg-primary-hover);
		}
	}

	&___secondary {
		color: var(--button-text-secondary);
		border-color: var(--color-border--light);
		background-color: var(--button-bg-secondary);

		&:hover,
		&:focus {
			box-shadow: inset 0 -4.5em 0 0 var(--button-bg-secondary-hover);
			color: var(--button-text-primary);
		}
	}

	&___tertiary {
		color: var(--button-text-primary);
		background: var(--button-bg-tertiary);
		border: none;

		&:hover,
		&:focus {
			box-shadow: inset 0 -4.5em 0 0 var(--button-bg-tertiary-hover);
		}
	}
	&___negative {
		color: var(--button-text-primary);
		background: transparent;
		border: var(--border--md) solid var(--color-primary) ;

		&:hover,
		&:focus {
			box-shadow: inset 0 -4.5em 0 0 var(--button-bg-tertiary-hover);
		}
	}
	&___inactive,
	&:disabled {
		opacity: 0.5;
		pointer-events: none;

		&:hover,
		&:focus {
			transition: none;
		}
	}
}

// Input
@mixin input {
	@include paragraph;
	outline: none;
	padding: var(--spacing--sm) var(--spacing--base);

	&.hasError {
		border-color: var(--color-error);
		color: var(--color-error);
	}

	&::placeholder {
		color: currentColor;
	}

	// Used for text, email, password, number etc.
	&___string {
		width: 100%;
		border: var(--border--sm) solid transparent;
		background: var(--color-gradient--5);
		-webkit-appearance: none;

		&.hasError {
			border-color: var(--color-error);
		}
	}

	&___string:focus,
	&___active {
		// outline-color: transparent;
		border-color: currentColor;
	}

	// Used for checkbox and radio
	&___choice {
		height: 1.5em;
		width: 1.5em;
	}

	// Used for textarea
	&___textarea {
		width: 100%;
		border: var(--border--sm) solid currentColor;
		-webkit-appearance: none;

		&.hasError {
			border-color: var(--color-error);
		}
	}
}

@import "ui/styles/2-tools/tools.media-query.scss";

.GlobalNewsPage_byline{
	display: flex;
	flex-wrap: wrap;
	padding: 1rem;
    margin-bottom: 5rem;
	background: var(--module-bg-footer);
	@include mq("md") {
		flex-wrap: nowrap;
	}
}


/* ------------------------------------ *\
		tools.typography-mixins
\* ------------------------------------ */
@import "ui/styles/2-tools/tools.media-query.scss";

@mixin heading--xl {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--bold);
	font-size: var(--font-size--4xl);
	line-height: var(--line-height--2xs);
	@include mq("sm") {
		font-size: var(--font-size--5xl);
	}
}

@mixin heading--lg {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--bold);
	font-size: var(--font-size--3xl);
	line-height: var(--line-height--sm);
	@include mq("sm") {
		font-size: var(--font-size--4xl);
	}
}

@mixin heading--md {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--bold);
	font-size: var(--font-size--2xl);
	line-height: var(--line-height--md);
}

@mixin heading--sm {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--bold);
	font-size: var(--font-size--xl);
	line-height: var(--line-height--md);
}

@mixin heading--xs {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--bold);
	font-size: var(--font-size--md);
	line-height: var(--line-height--lg);
}

@mixin manchet {
	font-family: var(--font-primary);
	font-size: var(--font-size--lg);
	line-height: var(--line-height--md);
}

@mixin paragraph {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--md);
	line-height: var(--line-height--xl);
}

@mixin text-link--md {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--bold);
	font-size: var(--font-size--md);
	line-height: var(--line-height--xl);
}

@mixin text-link--sm {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--bold);
	font-size: var(--font-size--sm);
	line-height: var(--line-height--xl);
}

@mixin formfield-label {
	font-family: var(--font-primary);
	font-size: var(--font-size--sm);
	font-weight: var(--font-weight--normal);
	color: currentColor;
	width: 100%;
	cursor: pointer;
}

@mixin line-clamp($lines) {
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

@mixin text-shadow {
	text-shadow: 1px 1px 8px var(--color-primary-lighter);
}
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.HeaderMember {
	position: sticky;
	top: 0;
	font-size: var(--font-size--sm);
	color: var(--color-light);
	padding: 1.5em var(--spacing--base);
	z-index: 9;
	background-color: var(--color-primary);

	&[aria-expanded="true"] {
		background-color: var(--color-primary) !important;
		padding-left: 0;
		padding-right: 0;
	}

	@include mq("lg") {
		height: 190px;
	}
}

.HeaderMember_logo {
	height: auto;
	max-height: 70px;
	min-width: fit-content;

	@include mq("sm", max) {
		svg {
			width: 40px;
			height: 70px;

			// Hiding the text on SVG logo
			g:nth-child(2) {
				display: none;
			}
		}
	}

	@include mq("lg") {
		padding-left: 0;
	}

	@include mq("lg") {
		padding-left: 0;
	}

	//If search is expanded
	[aria-expanded="true"] & {
		display: none;
	}
}

.HeaderMember_navContainer {
	text-align: right;
	flex: 1;
	overflow: hidden;
	margin-top: 2rem;
	margin-bottom: 2rem;
	padding: 0;

	@include transition {
		transition: max-width var(--trans-time--standard) ease;
	}

	@include mq("lg") {
		position: fixed;
		background-color: var(--color-light);
		box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.25);
		left: 0;
		width: 178px;
		height: 100vh;
		top: 19rem;
		margin-top: 0;
		z-index: 2;
		padding-right: var(--grid-gutter);
		padding-left: var(--grid-gutter);
		padding-top: 12rem;
	}

	@include mq("lg", max) {
		order: 2;
		flex: initial;
	}
}

.HeaderMember_serviceNavContainer {
	display: flex;
	flex-direction: column-reverse;
	padding: 2rem;
	background-color: var(--color-primary);

	.HeaderMember_nav___active & {
		position: fixed;
		bottom: 0;
		left: 0rem;
		z-index: 1;
	}

	@include mq("lg") {
		position: static;
		padding: 0;
		margin: auto -5%;
		flex-direction: column;
		align-items: center;
	}
}

.HeaderMember_nav {
	display: block;
	position: absolute;
	width: 100%;
	height: 100vh;
	top: 100px;
	left: -100%;
	margin: auto;
	padding: var(--spacing--base);
	background-color: var(--color-primary);

	@include transition {
		transition: left var(--trans-time--standard) ease;
	}

	@include mq("lg") {
		overflow: none;
		background-color: transparent;
		height: auto;
		padding: 0;
		position: static;
	}

	&___active {
		left: 0;
	}
}

.HeaderMember_navWrapper {
	height: 70vh;
	overflow: auto;

	@include mq("lg") {
		height: auto;
		justify-content: space-evenly;
	}
}

.HeaderMember_navController {
	display: none;

	&:checked~.HeaderMember_nav {
		display: block;
	}
}

.HeaderMember_navToggle {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 35px;

	@include mq("lg") {
		display: none;
	}

	//If search is expanded
	[aria-expanded="true"] & {
		display: none;
	}

	//If menu is expanded
	&[aria-expanded="true"] {
		.HeaderMember_iconBar {
			&:first-child {
				opacity: 0;
			}

			&:last-child {
				opacity: 0;
			}

			&:nth-child(2) {
				transform: rotate(45deg) translateY(-50%);
				opacity: 1;
			}

			&:nth-child(3) {
				transform: rotate(-45deg) translateY(-50%);
				opacity: 1;
			}
		}
	}
}

.HeaderMember_icon {
	width: 2em;
	height: 2em;
	display: inline-block;
	position: relative;
}

.HeaderMember_iconBar {
	display: block;
	width: 100%;
	height: 0px;
	position: absolute;
	background: currentColor;
	border: 0.8px solid currentColor;
	opacity: 0;
	top: 50%;
	transform: rotate(0) translateY(-50%);

	&:first-child {
		margin-top: -0.5em;
		opacity: 1;
	}

	&:last-child {
		margin-top: 0.5em;
		opacity: 1;
	}
}

.HeaderMember_memberContainer {
	display: flex;
	align-items: center;
	margin-top: var(--spacing--md);

	@include mq("lg", max) {
		background-color: var(--color-primary);
	}

	.HeaderMember_nav___active & {
		position: fixed;
		bottom: 8.2rem;
		left: 0rem;
		border-top: 1px solid white;
		padding: 1rem 2rem;
	}

	@include mq("lg") {
		opacity: 1;
		width: auto;
		position: absolute;
		left: unset;
		right: 2rem;
		bottom: 5rem;
		color: var(--button-text-secondary);
		border-color: var(--color-border--light);
		padding-right: var(--grid-gutter);
		padding-left: var(--grid-gutter);
	}
}

.HeaderMember_someLinkText {
	padding-left: var(--spacing--md);
	font-size: var(--font-size--lg);
	font-weight: var(--font-weight--light);
}

.HeaderMember_cart {
	@include mq("md", max) {
		order: 1;
		margin-right: var(--spacing--sm);
	}
}

.HeaderMember_memberLink {
	width: 100%;
	color: var(--button-text-secondary);
	background-color: var(--button-bg-secondary);
	margin-top: 2rem;
	width: 50%;
	background: transparent;
	font-weight: var(--font-weight--semibold);
	cursor: pointer;
	display: inline-block;
	padding: var(--spacing--sm) var(--spacing--md);
	text-decoration: none;
	line-height: var(--line-height--lg);
	border-radius: var(--corner-size--base);
	border: var(--border--md) solid var(--color-border--light);
	text-align: center;
	min-width: 10em;
	transition: all var(--trans-time--standard);
	line-height: var(--line-height--2xl);

	@include mq("lg") {
		width: auto;
		margin-top: 0;
		display: block;
	}

	&:hover {
		box-shadow: inset 0 -4.5em 0 0 var(--button-bg-secondary-hover);
		color: var(--button-text-primary);
	}
}

.HeaderMember_iconLoop {
	transform: rotate(90deg);
	width: auto;
	height: auto;
	margin-bottom: 2px;

	@include transition {
		transition: left var(--trans-time--standard) ease;
	}

	:first-child {
		fill: transparent;
	}

	.ServiceNavigation_link:hover & {
		left: 0.3rem;
	}
}

.HeaderMember_iconLock {
	width: auto;
	height: auto;
	margin-bottom: 2px;

	@include transition {
		transition: left var(--trans-time--standard) ease;
	}

	:first-child {
		fill: transparent;
	}

	.ServiceNavigation_link:hover & {
		left: 0.3rem;
	}
}

.HeaderMember_serviceItem {
	list-style-type: none;

	@include mq("lg") {
		display: none;
	}

	&___primary {
		padding-left: 3rem;
		padding-right: 3rem;

		svg {
			width: 28px;
			height: 25px;
		}
	}

	//If search is expanded
	[aria-expanded="true"] & {
		display: none;
	}
}

.HeaderMember_link {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-weight: normal;
}

.HeaderMember_navToggleText {
	&[aria-expanded="true"] {
		display: none;
	}
}

.HeaderMember_navToggleTextClose {
	display: none;

	&[aria-expanded="true"] {
		display: block;
	}
}

.HeaderMember_iconGlobe {
	margin-right: 5px;

	svg {
		fill: transparent;
	}

	path {
		stroke: var(--color-white);

		@include mq("lg") {
			[aria-selected="true"] & {
				stroke: var(--color-primary);
			}
		}
	}
}

.HeaderMember_iconChevron {
	margin-left: 5px;
	position: relative;
	top: 7px;
	width: 15px;
	height: 10px;

	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}

	[aria-selected="true"] & {
		transform: rotate(180deg);
	}

	svg {
		fill: transparent;
	}

	path {
		stroke: var(--color-white);

		@include mq("lg") {
			[aria-selected="true"] & {
				stroke: var(--color-primary);
			}
		}
	}
}

.HeaderMember_languageLink {
	display: inline-flex;
	font-weight: var(--font-weight--bold);
	cursor: pointer;
	z-index: 9;
	width: 100%;
	font-size: 15px;
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 2rem;

	@include mq("lg") {
		margin-bottom: 0;
		justify-content: space-evenly;

		&[aria-selected="true"] {
			color: var(--color-primary);
		}
	}
}

.HeaderMember_languageContainer {
	animation: fadeInDown 500ms ease-in-out;
	color: var(--color--light);

	border-radius: 1rem;
	text-align: left;
	width: 100%;

	@include mq("lg") {
		position: absolute;
		top: 2rem;
		left: 0;
		padding: 4rem 2rem 1rem 2rem;
		background: var(--color-light);
		color: var(--color-primary);
	}
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translateY(-10px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.HeaderMember_languageItemContainer {
	margin-bottom: 2rem;

	@include mq("lg") {
		margin-top: 2rem;
		margin-bottom: 0;
	}
}

.HeaderMember_languageItems {
	list-style-type: none;
	width: 110%;
	padding: 0.6rem 0;
	border-radius: 0.4rem;
	font-weight: var(--font-weight--bold);
	cursor: pointer;

	@include mq("lg") {
		position: relative;
		right: 5%;
		padding: 0.6rem 2rem;

		&:hover {
			background-color: var(--color-tertiary-lighter);
		}
	}

	@include transition {
		transition: background-color var(--trans-time--standard) ease;
	}
}

.HeaderMember_searchField {
	height: 100px;
}

.HeaderMember_profileImage {
	width: 3rem;
	height: 3rem;
	margin-right: 2rem;
	object-fit: cover;
	border-radius: 50%;
}

.HeaderMember_profile {
	display: flex;
	align-items: center;
	margin-right: 2rem;
	width: 50%;
	position: relative;
	top: 1rem;

	@media (min-width: 480px) {
		margin-right: 7rem;
	}

	@include mq("lg") {
		top: 0;
		width: auto;
	}
}

.HeaderMember_profileName {
	display: none;
	font-size: 1.6rem;
	font-weight: var(--font-weight--bold);

	@include mq("lg") {
		display: block;
	}
}

.HeaderMember_profileLink {
	font-size: 1.4rem;
	display: inline-block;
	color: currentColor;
	position: relative;
	text-decoration: none;
	align-items: center;
	max-width: fit-content;
	cursor: pointer;

	&:after {
		@include transition {
			transition: width var(--trans-time--standard) ease;
		}

		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 0%;
		content: ".";
		color: transparent;
		background: var(--color-light);
		height: 1px;
	}

	&:hover:after {
		width: 100%;
	}
}

.HeaderMember_iconPerson {
	width: auto;
	height: auto;
	margin-bottom: 2px;

	@include transition {
		transition: left var(--trans-time--standard) ease;
	}

	:first-child {
		fill: transparent;
	}

	.ServiceNavigation_link:hover & {
		left: 0.3rem;
	}
}

.HeaderMember_serviceMail {
	display: inline-flex;
	width: 20px;
	height: 20px;
	justify-content: center;
	background: var(--color-secondary);
	align-items: center;
	border-radius: 50%;
	font-size: 10px;
	font-weight: bold;
	color: var(--color-primary);
	position: absolute;
	right: 165px;
	top: 35px;

	@include mq("lg") {
		right: 145px;
		top: 25px;
	}
}


.HeaderMember_impersonationMessage {
	background: var(--color-secondary);
	position: absolute;
	left: 0;
	right: 0;
	bottom: -2.5rem;
	padding: 1rem;
	text-align: center;
	z-index: 2;

	@include mq("lg") {
		bottom: -1.5rem;
	}
}
@import "ui/styles/2-tools/tools.media-query.scss";

.NewsPage_byline{
	display: flex;
	flex-wrap: wrap;
	padding: 1rem;
    margin-bottom: 5rem;
	background: var(--module-bg-footer);
	@include mq("md") {
		flex-wrap: nowrap;
	}
}

.NewsPage_wrapper{
	margin-bottom: 5rem;
	@include mq("md") {
		display: grid;
		grid-template-columns: 3fr 7fr;
		grid-column-gap: 1em;
	}
}
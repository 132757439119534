@import "ui/styles/2-tools/tools.typography-mixins-tillidsportal.scss";

.documentWrapper{
	animation: fadeInDown 500ms ease-in-out;
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.documentHeading {
	margin-top: 40px;
	@include t-h2;
	color: var(--color-primary);
}
.documentTeaser {
	margin-top: 30px;
	@include t-body;
	color: var(--color-primary);
}


@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/_tools.typography-mixins-tillidsportal.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.RegisterOverlay{
	text-align: center;
	
	@include mq("lg") {	
		padding: 1rem;
	}	
}
.RegisterOverlay_linkWrapper{
	margin-top: 2rem;
	overflow: auto;
    height: 120%;
	@include mq("md-lg") {	
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 2rem;
		height: auto;
	}	
	// Targeting tag because this heading is imported from another component 
	h4{
		font-size: 2rem;
	
		@include mq("lg") {	
			font-size: 2.4rem;
		}	
	}
}
.RegisterOverlay_linkItems{   
	width: 100%;
	min-height: 15rem;   
	background-color: var(--color-tertiary-lighter);
	border-radius: 0 2rem 0 0;
	margin-bottom: 2rem;
	&:hover{
		box-shadow: unset;
		background-color: var(--button-bg-tertiary-hover);
	}
	@include mq("lg") {	
		padding: 5rem 2rem 2rem 2rem;
	}
}
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.mixins.scss";
@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins-tillidsportal.scss";
@import "ui/styles/2-tools/tools.grid-mixins.scss";
@import "ui/styles/2-tools/tools.space.scss";

.Feedback{
	color: var(--color-primary);
	width: 100%;
	@include module-seperator;
}
.Feedback_heading{
	@include t-h2;	
	margin-bottom: 1.5rem;	
}
.Feedback_subHeading{
	@include t-body;	
	margin-bottom: 3.2rem;
	@include mq("md") {		
		width: 60%;
		margin: 0 0 2.4rem 0;
	} 
}
.Feedback_textarea{
	background: var(--color-light)!important;	
	@include input;	
	width: 100%;
	border: var(--border--sm) solid var(--color-tertiary);
	border-radius: var(--corner-size--xs);
	padding: 2rem;
	height: 21rem;
	line-height: var(--line-height--md);
	margin-bottom: 2.4rem;
	resize: none;

	.isDisabled & {
		cursor: not-allowed;
	}

	&::placeholder {
		color: var(--color-tertiary-darker);
	}
	label{
		position: absolute;		
		visibility: hidden;
	}

}
.Feedback_formWrapper{
	display: flex;	
	flex-direction: column-reverse;
	@include mq("md") {
		flex-direction: row;
	} 
}
.Feedback_inputHeading{
	@include t-h4;
	margin-bottom: 1rem;
}
.Feedback_form{	
	@include mq("md") {
		flex: 0 0 50%;	
	}  
}
.Feedback_graphicWrapper{
	display: flex;
    flex-direction: column-reverse;
	margin: 0 auto;
	margin-bottom: 3rem;
	@include mq("md") {
		flex-direction: column;
		margin-bottom: 0;
	} 
}
.Feedback_graphic{
	display: block;
	color: transparent;
    width: auto;
    height: 20rem;  
}
.Feedback_graphicText{
	font-size: var(--font-size--sm);
	font-weight: var(--font-weight--bold);
	text-align: center;
	@include mq("md") {
		font-size: var(--font-size--lg);
	} 
}
.Feedback_button{
	width: 100%;
	@include mq("md") {
		width: auto;
	} 
}
.Feedback__messageIcon{
	margin-right: .5rem
}

.Feedback_successText{
	display: inline-flex;
    width: 100%;
	color: var(--color-tag-green);
	position: relative;
    top: -2rem;
}
.Feedback_failText{
	display: inline-flex;
    width: 100%;
	color: var(--color-error);
	position: relative;
    top: -2rem;
}
.Feedback_spinner{
	margin-bottom: 0;
}
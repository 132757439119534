@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.HeaderDef {
	position: sticky;
	top: 0;
	font-size: var(--font-size--sm);
	color: var(--color-light);
	padding: 1.1rem var(--spacing--base);
	z-index: 9;
	background-color: var(--color-primary);
	&___active {
		background-color: var(--color-primary) !important;
	}
	@include mq("lg") {
		padding: 1.9em var(--spacing--base);
	}
}

.HeaderDef_logo {
	height: auto;
	max-height: 70px;
	@include mq("sm", max) {
		svg {
			width: 40px;
			height: 70px;
			// Hiding the text on SVG logo
			g:nth-child(2) {
				display: none;
			}
		}
	}
}

.HeaderDef_navigation {
	text-align: right;
	flex: 1;
	overflow: hidden;
	margin-top: 2rem;
	margin-bottom: 2rem;
	@include mq("lg") {
		margin-top: 0;
		margin-bottom: 0;
		padding-bottom: 1rem;
	}
	@include mq("lg", max) {
		order: 2;
		flex: initial;
	}
}
.HeaderDef_navContainer {
	position: relative;
	display: flex;
	flex-direction: column-reverse;
	padding: 0;
	margin-bottom: auto;
	@include mq("lg") {
		margin: auto -5%;
		flex-direction: column;
		align-items: center;
	}
}
.HeaderDef_memberLink {
	width: 100%;
	color: var(--button-text-secondary);
	background-color: var(--button-bg-secondary);
	margin-top: 2rem;
	width: 50%;
	background: transparent;
	font-weight: var(--font-weight--semibold);
	cursor: pointer;
	display: inline-block;
	padding: var(--spacing--sm) var(--spacing--md);
	text-decoration: none;
	line-height: var(--line-height--lg);
	border-radius: var(--corner-size--base);
	border: var(--border--md) solid var(--color-border--light);
	text-align: center;
	min-width: 10em;
	transition: all var(--trans-time--standard);
	line-height: var(--line-height--2xl);
	@include mq("lg") {
		width: auto;
		margin-top: 0;
		display: block;
	}
	&:hover {
		box-shadow: inset 0 -4.5em 0 0 var(--button-bg-secondary-hover);
		color: var(--button-text-primary);
	}
}

.HeaderDef_nav {
	display: block;
	position: absolute;
	width: 100%;
	height: 100vh;
	top: 105px;
	left: -100%;
	margin: auto;
	padding: 1rem var(--spacing--base);
	background-color: var(--color-primary);
	@include transition {
		transition: left var(--trans-time--standard) ease;
	}

	@include mq("lg") {
		overflow: none;
		background-color: transparent;
		height: auto;
		padding: 0;
		position: static;
	}

	&___active {
		left: 0;
	}
}

.HeaderDef_navWrapper {
	height: 80vh;
	overflow: auto;
	display: block;
	@include mq("lg") {
		display: flex;
		height: auto;
	}
}

.HeaderDef_navController {
	display: none;

	&:checked ~ .HeaderDef_nav {
		display: block;
	}
}
.HeaderDef_navToggle {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 35px;
	@include mq("lg") {
		display: none;
	}
	&[aria-expanded="true"] {
		.HeaderDef_iconBar {
			&:first-child {
				opacity: 0;
			}
			&:last-child {
				opacity: 0;
			}
			&:nth-child(2) {
				transform: rotate(45deg) translateY(-50%);
				opacity: 1;
			}
			&:nth-child(3) {
				transform: rotate(-45deg) translateY(-50%);
				opacity: 1;
			}
		}
	}
}
.HeaderDef_icon {
	width: 2em;
	height: 2em;
	display: inline-block;
	position: relative;
}

.HeaderDef_iconBar {
	display: block;
	width: 100%;
	height: 0px;
	position: absolute;
	background: currentColor;
	border: 0.8px solid currentColor;
	opacity: 0;
	top: 50%;
	transform: rotate(0) translateY(-50%);

	&:first-child {
		margin-top: -0.5em;
		opacity: 1;
	}

	&:last-child {
		margin-top: 0.5em;
		opacity: 1;
	}
}

.HeaderDef_memberContainer {
	padding: 0;
	margin-top: var(--spacing--md);
	padding: 0;
	@include mq("lg") {
		min-width: 204px;
		margin-top: 0;
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: space-evenly;
		width: auto;
		position: absolute;
		right: 2rem;
		bottom: 1rem;
		margin-top: var(--spacing--lg);
		color: var(--button-text-secondary);
		border-color: var(--color-border--light);
		padding-right: var(--grid-gutter);
		padding-left: var(--grid-gutter);
	}
}
.HeaderDef_someLinkText {
	padding-left: var(--spacing--md);
	font-size: var(--font-size--lg);
	font-weight: var(--font-weight--light);
}

.HeaderDef_cart {
	@include mq("md", max) {
		order: 1;
		margin-right: var(--spacing--sm);
	}
}
.HeaderDef_memberLink {
	width: 100%;
	color: var(--color-primary);
	border-color: var(--button-bg-primary);
	background-color: var(--button-bg-primary);
	margin-top: 2rem;
	@include mq("lg") {
		margin-top: 0;
		display: block;
		color: var(--button-text-secondary);
		border-color: var(--color-border--light);
		background-color: transparent;
	}
}

.HeaderDef_iconLoop {
	transform: rotate(90deg);
	width: auto;
	height: auto;
	margin-bottom: 2px;
	@include transition {
		transition: left var(--trans-time--standard) ease;
	}
	:first-child {
		fill: transparent;
	}

	.ServiceNavigation_link:hover & {
		left: 0.3rem;
	}
}

.HeaderDef_iconLock {
	width: auto;
	height: auto;
	margin-bottom: 2px;
	@include transition {
		transition: left var(--trans-time--standard) ease;
	}
	:first-child {
		fill: transparent;
	}
	.ServiceNavigation_link:hover & {
		left: 0.3rem;
	}
}
.HeaderDef_serviceItem {
	list-style-type: none;

	@include mq("lg") {
		display: none;
	}
	&___primary {
		margin-left: auto;
		svg {
			width: 28px;
			height: 25px;
		}
	}
	&___secondary {
		padding-left: 2rem;
		padding-right: 2rem;
		svg {
			width: 20px;
			height: 25px;
		}
	}
}
.HeaderDef_link {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-weight: normal;
	min-width: unset;
	background: none;
	padding: 0;
	color: white;
	border-radius: unset;
	line-height: unset;
	&:focus {
		box-shadow: unset;
	}
	&:hover {
		box-shadow: none;
	}
}
.HeaderDef_navToggleText {
	&[aria-expanded="true"] {
		display: none;
	}
}
.HeaderDef_navToggleTextClose {
	display: none;
	&[aria-expanded="true"] {
		display: block;
	}
}
.HeaderDef_iconGlobe {
	margin-right: 5px;
	svg {
		fill: transparent;
	}
	path {
		stroke: var(--color-white);

		@include mq("lg") {
			[aria-selected="true"] & {
				stroke: var(--color-primary);
			}
		}
	}
}
.HeaderDef_iconChevron {
	margin-left: 5px;
	position: relative;
	top: 7px;
	width: 15px;
	height: 10px;
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}
	[aria-selected="true"] & {
		transform: rotate(180deg);
	}
	svg {
		fill: transparent;
	}
	path {
		stroke: var(--color-white);

		@include mq("lg") {
			[aria-selected="true"] & {
				stroke: var(--color-primary);
			}
		}
	}
}
.HeaderDef_languageLink {
	display: inline-flex;
	font-weight: var(--font-weight--bold);
	cursor: pointer;
	z-index: 9;
	width: 100%;
	font-size: 15px;
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 2rem;
	@include mq("lg") {
		margin-bottom: 0;
		justify-content: space-evenly;
		&[aria-selected="true"] {
			color: var(--color-primary);
		}
	}
}

.HeaderDef_languageContainer {
	animation: fadeInDown 500ms ease-in-out;
	color: var(--color--light);

	border-radius: 1rem;
	text-align: left;
	width: 100%;
	@include mq("lg") {
		position: absolute;
		top: 2rem;
		left: 0;
		padding: 4rem 2rem 1rem 2rem;
		background: var(--color-light);
		color: var(--color-primary);
	}
}
@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translateY(-10px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
.HeaderDef_languageItemContainer {
	margin-bottom: 2rem;
	@include mq("lg") {
		margin-top: 2rem;
		margin-bottom: 0;
	}
}
.HeaderDef_languageItems {
	list-style-type: none;
	width: 110%;
	padding: 0.6rem 0;
	border-radius: 0.4rem;
	font-weight: var(--font-weight--bold);
	cursor: pointer;
	@include mq("lg") {
		position: relative;
		right: 5%;
		padding: 0.6rem 2rem;
		&:hover {
			background-color: var(--color-tertiary-lighter);
		}
	}
	@include transition {
		transition: background-color var(--trans-time--standard) ease;
	}
}

.HeaderDef_searchField {
	height: 100px;
}
.new_logo_test {
	width:150px;
}